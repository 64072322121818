/**
 * @description this file includes step one, two and three in Motor Purchase Flow
 */

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@Redux/store';
import { IInitState } from '@models/purchaseFlow.model';

const initialState: IInitState = {
  app: null,
  next: false,
  prev: false,
  step: null,
  isLoading: false,
  currentDriversPercentageError: false,
  currentQuoteTab: null,
  quoteExpiryTime: null,
  masterTplQuote: [],
  masterCompQuote: [],
  masterOwnDamageQuote: [],
  tplQuote: [],
  compQuote: [],
  ownDamageQuote: [],
  coupon: null,
  previousInsuranceProvider: null,
  selectedDeductible: null,
  quoteSelection: null,
  quoteConfig: {},
  cheaperQuote: null,
  selectedQuoteIndex: 0,
  selectedQuote: null,
  isPrevQuote: false,
  policyHolderDetails: null,
  confirmationPopup: {},
  quoteSortType: null,
  isCompLoading: false,
  isAgreementBeingCalled: false,
  summaryPageContent: null,
  filterQuoteListContent: null,
};

export const purchaseFlowSlice = createSlice({
  name: 'PurchaseFlow',
  initialState,
  reducers: {
    setApplication: (state, action: PayloadAction<IInitState['app']>) => {
      state.app = action.payload;
      sessionStorage.setItem('applicationData', JSON.stringify(action.payload));
      if (action.payload?.model?.token) localStorage.setItem('token', action.payload?.model?.token);
    },
    fireNext: (state, action: PayloadAction<IInitState['next']>) => {
      state.next = action.payload;
    },
    setStepperStep: (state, action: PayloadAction<IInitState['step']>) => {
      state.step = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<IInitState['isLoading']>) => {
      state.isLoading = action.payload;
    },

    setCurrentDriversPercentageError: (
      state,
      action: PayloadAction<IInitState['currentDriversPercentageError']>,
    ) => {
      state.currentDriversPercentageError = action.payload;
    },
    setQuoteSelection: (state, action: PayloadAction<IInitState['quoteSelection']>) => {
      state.quoteSelection = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<IInitState['currentQuoteTab']>) => {
      state.currentQuoteTab = action.payload;
    },
    setQuoteExpiryTime: (state, action: PayloadAction<IInitState['quoteExpiryTime']>) => {
      state.quoteExpiryTime = action.payload;
    },
    setTplQuote: (state, action: PayloadAction<IInitState['tplQuote']>) => {
      state.tplQuote = action.payload;
    },
    setCompQuote: (state, action: PayloadAction<IInitState['compQuote']>) => {
      state.compQuote = action.payload;
    },
    setOwnDamageQuote: (state, action: PayloadAction<IInitState['ownDamageQuote']>) => {
      state.ownDamageQuote = action.payload;
    },
    setCoupon: (state, action: PayloadAction<IInitState['coupon']>) => {
      state.coupon = action.payload;
    },
    setPrevInsuranceProvider: (
      state,
      action: PayloadAction<IInitState['previousInsuranceProvider']>,
    ) => {
      state.previousInsuranceProvider = action.payload;
    },
    setQuoteConfig: (state, action: PayloadAction<IInitState['quoteConfig']>) => {
      state.quoteConfig = action.payload;
    },
    setMasterTplQuote: (state, action: PayloadAction<IInitState['masterTplQuote']>) => {
      state.masterTplQuote = action.payload;
    },
    setMasterCompQuote: (state, action: PayloadAction<IInitState['masterCompQuote']>) => {
      state.masterCompQuote = action.payload;
    },
    setMasterOwnDamageQuote: (state, action: PayloadAction<IInitState['masterOwnDamageQuote']>) => {
      state.masterOwnDamageQuote = action.payload;
    },
    setSelectedQuote: (state, action: PayloadAction<IInitState['selectedQuote']>) => {
      state.selectedQuote = action.payload;
    },
    setIsPrevQuote: (state, action: PayloadAction<IInitState['isPrevQuote']>) => {
      state.isPrevQuote = action.payload;
    },
    setSelectedQuoteIndex: (state, action: PayloadAction<IInitState['selectedQuoteIndex']>) => {
      state.selectedQuoteIndex = action.payload;
    },
    setSelectedDeductible: (state, action: PayloadAction<IInitState['selectedDeductible']>) => {
      state.selectedDeductible = action.payload;
    },
    setCheapterQuote: (state, action: PayloadAction<IInitState['cheaperQuote']>) => {
      state.cheaperQuote = action.payload;
    },
    setPolicyHolderDetails: (state, action: PayloadAction<IInitState['policyHolderDetails']>) => {
      state.policyHolderDetails = action.payload;
    },
    setConfirmationPopup: (state, action: PayloadAction<IInitState['confirmationPopup']>) => {
      state.confirmationPopup = action.payload;
    },
    setQuoteSortType: (state, action: PayloadAction<IInitState['quoteSortType']>) => {
      state.quoteSortType = action.payload;
    },
    setIsCompLoading: (state, action: PayloadAction<IInitState['isCompLoading']>) => {
      state.isCompLoading = action.payload;
    },
    setIsAgreementBeingCalled: (
      state,
      action: PayloadAction<IInitState['isAgreementBeingCalled']>,
    ) => {
      state.isAgreementBeingCalled = action.payload;
    },
    setSummaryPageContent: (state, action: PayloadAction<IInitState['summaryPageContent']>) => {
      state.summaryPageContent = action.payload;
    },
    setFilterQuoteListContent: (
      state,
      action: PayloadAction<IInitState['filterQuoteListContent']>,
    ) => {
      state.filterQuoteListContent = action.payload;
    },
  },
});

export const {
  setApplication,
  fireNext,
  setStepperStep,
  setIsLoading,
  setCurrentDriversPercentageError,
  setCurrentTab,
  setQuoteExpiryTime,
  setTplQuote,
  setCompQuote,
  setOwnDamageQuote,
  setQuoteSelection,
  setMasterCompQuote,
  setMasterTplQuote,
  setMasterOwnDamageQuote,
  setSelectedDeductible,
  setCoupon,
  setQuoteConfig,
  setSelectedQuoteIndex,
  setSelectedQuote,
  setIsPrevQuote,
  setCheapterQuote,
  setPolicyHolderDetails,
  setConfirmationPopup,
  setQuoteSortType,
  setIsCompLoading,
  setIsAgreementBeingCalled,
  setPrevInsuranceProvider,
  setSummaryPageContent,
  setFilterQuoteListContent,
} = purchaseFlowSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const purchaseApp = (state: RootState) => state.purchase.app;

export const selectNext = (state: RootState) => state.purchase.next;

export const selectPrev = (state: RootState) => state.purchase.prev;

export const selectStep = (state: RootState) => state.purchase.step;

export const selectIsLoading = (state: RootState) => state.purchase.step;

export const selectedCurrentDriversPercentageError = (state: RootState) =>
  state.purchase.currentDriversPercentageError;

export const selectCurrentQuoteTab = (state: RootState) => state.purchase.currentQuoteTab;

export const selectQuoteExpiryTime = (state: RootState) => state.purchase.quoteExpiryTime;

export const selectTplQuote = (state: RootState) => state.purchase.tplQuote;

export const selectCompQuote = (state: RootState) => state.purchase.compQuote;

export const selectOwnDamageQuote = (state: RootState) => state.purchase.ownDamageQuote;

export const selectCoupon = (state: RootState) => state.purchase.coupon;

export const selectPrevInsuranceProvider = (state: RootState) =>
  state.purchase.previousInsuranceProvider;

export const selectQuoteConfig = (state: RootState) => state.purchase.quoteConfig;

export const selectQuoteSelection = (state: RootState) => state.purchase.quoteSelection;

export const selectMasterCompQuote = (state: RootState) => state.purchase.masterCompQuote;

export const selectMasterTplQuote = (state: RootState) => state.purchase.masterTplQuote;

export const selectMasterOwnDamageQuote = (state: RootState) => state.purchase.masterOwnDamageQuote;

export const selectSelectedDeductible = (state: RootState) => state.purchase.selectedDeductible;

export const selectCheaperQuote = (state: RootState) => state.purchase.cheaperQuote;

export const selectSelectedQuoteIndex = (state: RootState) => state.purchase.selectedQuoteIndex;

export const selectSelectedQuote = (state: RootState) => state.purchase.selectedQuote;

export const selectIsPrevQuote = (state: RootState) => state.purchase.isPrevQuote;

export const selectPolicyHolderDetails = (state: RootState) => state.purchase.policyHolderDetails;

export const selectConfirmationPopups = (state: RootState) => state.purchase.confirmationPopup;

export const selectQuoteSortType = (state: RootState) => state.purchase.quoteSortType;

export const selectIsCompLoading = (state: RootState) => state.purchase.isCompLoading;

export const selectIsAgreementBeingCalled = (state: RootState) =>
  state.purchase.isAgreementBeingCalled;

export const selectSummaryPageContent = (state: RootState) => state.purchase.summaryPageContent;
export const selectFilterQuoteListContent = (state: RootState) =>
  state.purchase.filterQuoteListContent;

export default purchaseFlowSlice.reducer;
