import { IRoutes } from '@models/routes.model';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const HrefLang = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['routes']);
  const [currentKey, setCurrentKey] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    const pathURL = decodeURI(location.pathname);
    const routesObj = t('routes:routes', { returnObjects: true }) as IRoutes['routes'];
    const arRoutes = t('routes:routes', { lng: 'ar', returnObjects: true }) as any;
    const enRoutes = t('routes:routes', { lng: 'en', returnObjects: true }) as any;

    let matchedKey: string | null = null;

    for (const [key, value] of Object.entries(routesObj)) {
      if (
        value.toLocaleLowerCase() === pathURL.toLocaleLowerCase() ||
        (pathURL.toLocaleLowerCase() === arRoutes[key]?.toLocaleLowerCase() && language !== 'ar') ||
        (pathURL.toLocaleLowerCase() === enRoutes[key]?.toLocaleLowerCase() && language !== 'en')
      ) {
        matchedKey = key;
        break;
      }
    }

    setCurrentKey(matchedKey);
  }, [location.pathname, language, t]);

  if (!currentKey) {
    return null;
  }

  const hrefLangEn = `https://${window.location.hostname}${t(`routes:routes.${currentKey}`, {
    lng: 'en',
  })}`;
  const hrefLangAr = `https://${window.location.hostname}${t(`routes:routes.${currentKey}`, {
    lng: 'ar',
  })}`;

  return (
    <Helmet>
      <link rel='alternate' hrefLang='en' href={hrefLangEn} />
      <link rel='alternate' hrefLang='ar' href={hrefLangAr} />
    </Helmet>
  );
};

export default HrefLang;
