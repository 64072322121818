import Img from '@Components/common/Img';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TAside } from '@models/header.model';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import LoginPopup from '@Components/common/LoginPopup';
import useModal from '@Hooks/useModal';
import { dataLayerPush } from '@Services/ga4';
import { GA4Events } from '@Services/ga4Events';
import BurgerMenuIcon from '@Components/common/BurgerMenuIcon';
import TameeniLoader from '@Components/common/TameeniLoader';

export default function Aside({
  showAside,
  switchLanguage,
  closeAside,
}: {
  showAside: boolean;
  switchLanguage: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  closeAside: (e: React.MouseEvent<HTMLElement>) => void;
}) {
  const [show, setShow] = useState<string[] | null>(null);
  const [loginLoader, setLoginLoader] = useState<boolean>(false);
  const { t, i18n } = useTranslation(['header', 'translation', 'routes']);
  const lang = i18n.language;
  const auth = useAuth();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { showModal, modalTarget, handleShowModal, handleCloseModal } = useModal();
  useEffect(() => {
    i18n.loadLanguages('en').then(() => {
      setShow(null);
    });
  }, [showAside]);

  if (loginLoader) return <TameeniLoader />;

  return (
    <>
      <div
        className={`aside ${showAside ? 'active' : ''}`}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className='aside_logo'>
          {/* Logo */}
          <Link
            to={t('routes:routes.home')}
            lang={i18n.language}
            className='inline-flex justify-center items-center'
          >
            <Img
              src='/Resources/images/header/header-logo.svg'
              title={lang === 'en' ? 'Tameeni Compare and Buy Unsurance' : 'تأميني'}
              className='w-[100px] md:w-[120px]'
            />
          </Link>
          <div className='absolute start-2' onClick={(e) => closeAside(e)}>
            <BurgerMenuIcon handleClick={(e) => closeAside(e)} isActive />
          </div>
        </div>
        <ul className='aside_list list-none p-0 m-0'>
          {(t('header:aside', { returnObjects: true }) as TAside)?.map((item) => {
            return (
              (item.link !== '/TameeniOffers' || window.settings.VITE_IS_WALAA_ADS_ACTIVE) && (
                <li
                  className='aside_list_item'
                  onClick={() => {
                    show?.includes(item.id)
                      ? setShow(show.filter((id) => id !== item.id))
                      : show
                        ? (setShow([...show, item.id]),
                          dataLayerPush(GA4Events.sidebarDropdown.event, [
                            {
                              key: GA4Events.sidebarDropdown.params.clickText,
                              value: item.title,
                            },
                          ]))
                        : (setShow([item.id]),
                          dataLayerPush(GA4Events.sidebarDropdown.event, [
                            {
                              key: GA4Events.sidebarDropdown.params.clickText,
                              value: item.title,
                            },
                          ]));
                  }}
                  key={item.id}
                >
                  <Link
                    to={item?.link}
                    className={`aside_list_item_link ${item?.menu ? 'item_dropdown' : ''} ${
                      show?.includes(item.id) ? 'active' : ''
                    }`}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                      item?.menu ? e.preventDefault() : (closeAside(e), setShow(null)),
                        dataLayerPush(GA4Events.sidebarClick.event, [
                          {
                            key: GA4Events.sidebarClick.params.clickText,
                            value: item.title,
                          },
                        ]);
                    }}
                  >
                    <span className='item_icon'>
                      <Img src={item?.img} title={item.title} className='w-[30px]' />
                    </span>
                    <h6 className='item_text'>{item.title}</h6>
                  </Link>
                  {item.menu && (
                    <div
                      className={`item_dropdown_menu ${show?.includes(item.id) ? 'active' : ''}`}
                    >
                      {item?.menu?.map((menuItem) => {
                        return (
                          <Link
                            key={menuItem?.id}
                            to={menuItem?.link}
                            className='item_dropdown_menu_link '
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                              closeAside(e);
                              setShow(null);
                              dataLayerPush(GA4Events.sidebarClick.event, [
                                {
                                  key: GA4Events.sidebarClick.params.clickText,
                                  value: menuItem.title,
                                },
                                {
                                  key: GA4Events.sidebarClick.params.navTree,
                                  value: `${item?.title}/${menuItem.title}`,
                                },
                              ]);
                            }}
                          >
                            <h6 className='item_dropdown_menu_link_title'>{menuItem?.title}</h6>
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </li>
              )
            );
          })}
          <li className='aside_list_item md:hidden relative'>
            <Link
              to='/'
              className='aside_list_item_link'
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.stopPropagation();
                closeAside(e);
                if (
                  auth.isAuthenticated ||
                  (location.pathname === t('routes:routes.purchase-flow') &&
                    (Number(params.get('step')) === 3 ||
                      Number(params.get('step')) === 4 ||
                      Number(params.get('step')) === 5))
                ) {
                  e.preventDefault();
                  navigate('/Individual/ProfilePage', { replace: true });
                } else {
                  // auth.signinPopup().then((res) => {
                  //   if(res) {
                  //     res.access_token && ApplicationService.updateCreatedBy();
                  //     // res.access_token && reCallLastFailedRequest();
                  //     if (location.pathname === '/' && res.access_token)
                  //       navigate('/Individual/ProfilePage', { replace: true });
                  //   }
                  // });
                  dataLayerPush(GA4Events.loginStart.event, []);
                  const ele = document.getElementById('Login-popup-trigger');
                  ele && ele.click();
                }
              }}
            >
              <span className='item_icon'>
                <Img
                  src='/Resources/images/header/login-menu-icon.svg'
                  title={t('translation:login')}
                  className='w-[30px]'
                />
              </span>
              <h6 className='item_text'>
                {(location.pathname === t('routes:routes.purchase-flow') &&
                  Number(params.get('step')) === 3) ||
                Number(params.get('step')) === 4 ||
                (Number(params.get('step')) === 5 && !auth.isAuthenticated)
                  ? t('translation:quotations_list')
                  : !auth.isAuthenticated
                    ? t('translation:login')
                    : t('translation:myAccount')}
              </h6>
            </Link>
          </li>
          {auth.isAuthenticated && (
            <li className='aside_list_item md:hidden relative'>
              <Link
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setLoginLoader(true);
                  dataLayerPush(GA4Events.logout.event, []);
                  auth.signoutSilent().then(() => {
                    navigate('/', { replace: true });
                    localStorage.clear();
                    closeAside(e);
                    setLoginLoader(false);
                  });
                }}
                className='aside_list_item_link'
                to='/'
              >
                <span className='item_icon'>
                  <Img
                    src='/Resources/images/logout-menu-icon.svg'
                    title={t('translation:login')}
                    className='w-[30px]'
                  />
                </span>
                <h6 className='item_text'>
                  {auth.isAuthenticated ? t('translation:logout') : t('translation:login')}
                </h6>
              </Link>
            </li>
          )}
          <li className='aside_list_item md:hidden'>
            <Link to='/' id='switchLng' className='aside_list_item_link' onClick={switchLanguage}>
              <span className='item_icon'>
                <Img
                  src='/Resources/images/header/language-icon.svg'
                  title={t('translation:lang')}
                  className='w-[30px]'
                />
              </span>
              <h6 className='item_text'>{t('translation:lang')}</h6>
            </Link>
          </li>
        </ul>
      </div>
      <a
        id={'Login-popup-trigger'}
        className='hidden'
        href={'#Login-popup'}
        data-target-modal='#Login-popup'
        data-testid={'login-popup'}
        onClick={(e: React.MouseEvent<HTMLElement>) => handleShowModal(e)}
      />
      {showModal && modalTarget === '#Login-popup' && <LoginPopup handleClose={handleCloseModal} />}
    </>
  );
}
