import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Img from './Img';
import { useLocation } from 'react-router-dom';

export default function LoginPopup({ handleClose }: { handleClose: () => void }) {
  const [domReady, setDomReady] = useState(false);
  const [isRenewalFlow, setIsRenewalFlow] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setDomReady(true);
    if (pathname.includes('/RW')) {
      setIsRenewalFlow(true);
    }
  }, []);

  const element = (
    <>
      <div
        className='modal is-top custom-popup print-receipt-popup'
        id='Login-popup'
        onClick={(e: React.MouseEvent<HTMLDivElement>) => isRenewalFlow && e.stopPropagation()}
      >
        <div
          className='relative modal-dialog modal-md'
          onClick={(e: React.MouseEvent<HTMLDivElement>) => !isRenewalFlow && e.stopPropagation()}
        >
          <div className='modal-content p-0 overflow-hidden rounded-none'>
            {/* {!isRenewalFlow && ( */}
            <div
              className={`hidden modal-header w-[30px] h-[30px] end-[-30px] top-[16px] ${
                !isRenewalFlow ? 'visible' : 'invisible'
              } `}
            >
              <button
                type='button'
                className='close login-close w-[25px] h-[25px] right-auto left-1/2 -translate-x-1/2 -translate-y-1/2  top-1/2'
                id='login-popup-close-btn'
                onClick={handleClose}
              >
                {' '}
                <Img src={'/Resources/images/popup-close-icon.png'} alt='' title='' />
              </button>
            </div>
            {/* )} */}
            <div className='modal-body text-center'>
              <iframe
                frameBorder='0'
                className='w-full min-h-[620px] h-full'
                src='/login'
                title='loginIframe'
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return domReady ? ReactDOM.createPortal(element, document.getElementById('portal-modal')!) : null;
}
