import Layout from '@Components/Layout/Layout';
import TameeniLoader from '@Components/common/TameeniLoader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import ProviderRouter from './ProviderRouter';
import useUTM from '@Hooks/useUTM';
import DeprecatedVersionPopup from '@models/DeprecatedVersionPopup';
import useModal from '@Hooks/useModal';
import SessionExpirePopup from '@Components/common/SessionExpirePopup';
import ReactGA from 'react-ga4';
import { useAuth } from 'react-oidc-context';
import { Helmet } from 'react-helmet';
import SEO from '@Components/SEO';
import { useLocation } from 'react-router-dom';
import { hash } from './utils/commonUtils';
import HrefLang from '@Components/HrefLang';

export default function App() {
  ReactGA.initialize(window.settings.VITE_YOUR_MEASUREMENT_ID);
  const { i18n } = useTranslation();
  const [isLngLoaded, setIsLngLoaded] = useState<boolean>(false);
  const { version, isIOS } = useUTM();
  const auth = useAuth();
  const { showModal, modalTarget, handleShowModal } = useModal();
  const [currentLocation, setCurrentLocation] = useState<string>();

  const { pathname } = useLocation();

  useEffect(() => {
    i18n.loadLanguages('en').then(() => {
      setIsLngLoaded(true);
    });
  }, [i18n]);

  useEffect(() => {
    const profileObject =
      JSON.parse(
        localStorage.getItem(
          `oidc.user:${window?.settings?.VITE_AUTH_AUTHORITY}:${window?.settings?.VITE_AUTH_CLIENT_ID}`,
        ) as string,
      )?.profile ?? null;
    const hashedProfileData: { e?: string; p?: string } = {};

    hash(profileObject?.email).then((res) => {
      hashedProfileData.e = profileObject?.email ? res : undefined;
      hash(profileObject?.phone_number).then((res) => {
        hashedProfileData.p = profileObject?.phone_number ? res : undefined;

        ReactGA.send({
          hitType: 'pageview',
          page: pathname,
          language: i18n.language,
          country: 'sa',
          login_status: auth?.isAuthenticated ? 'logged' : 'not logged',
          tameeni_product: 'motor',
          page_type: 'home page',
          user_ID: auth?.isAuthenticated ? auth.user?.id_token : '',
          platform: navigator.userAgent.includes('HybridApp') ? 'app' : 'website',
          ...hashedProfileData,
        });
        if (location.protocol !== 'https:') {
          setCurrentLocation(location.href.replace('http:', 'https:'));
        } else {
          setCurrentLocation(location.href);
        }
      });
    });
  }, [pathname]);

  if (!isLngLoaded) {
    return <TameeniLoader />;
  }
  if (isIOS && version < Number(window.settings.OLDEST_NON_DEPRECATED_MOBILE_VERSION)) {
    <DeprecatedVersionPopup />;
  }

  return (
    <>
      <Helmet>
        <link rel='canonical' href={currentLocation} />
      </Helmet>
      <HrefLang />
      <SEO />
      <Layout>
        <ProviderRouter />
        <ToastContainer
          position='top-center'
          autoClose={5000}
          hideProgressBar
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <a
          id={'Sesssion-expire-popup-trigger'}
          className='hidden'
          href={'#Sesssion-expire-popup'}
          data-target-modal='#Sesssion-expire-popup'
          data-testid={'sesssion-expire-popup'}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleShowModal(e)}
        />
        {showModal && modalTarget === '#Sesssion-expire-popup' && <SessionExpirePopup />}
      </Layout>
    </>
  );
}
